import React, { Fragment, Component, RefObject } from 'react';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';

import AutoGrowingInput from './AutoGrowingInput';
import BaseInput from './BaseInput';

import { getCurrencySymbol } from 'businessLogic/server/scsToIcnAdapter';
import { ErrorAlert } from 'components/Shared/Icons/Icons';
import CpPopover from 'components/Shared/Popover/CpPopover';
import { PaymentAmountValidator } from 'shared/PaymentHelpers';
import { formatNumberWithCommaAndCurrency } from 'shared/utils';
import { breakpoints, fontSize, colors } from 'styles/cp';
import { TXN_MAP, PAYMENT_MAP } from 'types/constants';
const { AMOUNT_CONFIG } = PAYMENT_MAP;

export type Props = {
  paymentAmountChange: Function;
  paymentAmountReset: Function;
  intl: any;
  currency: string;
  balanceAmount: string;
  payment: {
    partialPaymentEnabled: boolean;
    inputAmount: string | number;
    defaultInputAmount: string | number;
    paymentStatus: any;
    isAmountValid: boolean;
    paymentMethodType: string;
    allowOverPay: boolean;
    isDateScheduled: boolean;
    isAutoPayOn: boolean;
  };
  featureFlags: any;
  region?: string;
  achOnlineConvenienceFeeEnabled?: boolean;
  achOnlineConvenienceFeeAmount?: number;
};

type State = {
  usePrevAmount: boolean;
  prevAmount: number;
  rawAmount: string | number;
  editMode: boolean;
  touched: boolean;
  timeout?: any | null;
  isCreditTooltipOpen: boolean;
  isSchedulePayTooltip: boolean;
};

class PaymentAmountInput extends Component<Props, State> {
  ref: RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);

    this.state = {
      editMode: false,
      touched: false,
      usePrevAmount: false,
      timeout: null,
      isCreditTooltipOpen: false,
      prevAmount: Number(props.payment.inputAmount),
      rawAmount: props.payment.inputAmount,
      isSchedulePayTooltip: false,
    };

    this.ref = React.createRef();
  }

  render() {
    const onBlur = () => {
      if (this.state.usePrevAmount) {
        this.setState(
          {
            editMode: false,
            rawAmount: this.state.prevAmount,
            usePrevAmount: false,
          },
          () => {
            updateReduxState();
          }
        );
      } else {
        updateReduxState();
        if (
          (isAmountValid && this.props.featureFlags['cp-amount-field-changes-improvement']) ||
          !this.props.featureFlags['cp-amount-field-changes-improvement']
        ) {
          this.setState({
            editMode: false,
            prevAmount: Number(this.state.rawAmount),
            usePrevAmount: false,
          });
        }
      }
    };

    const isAmountFieldChanges =
      this.props.featureFlags && this.props.featureFlags['cp-amount-field-changes-improvement'];

    const updateReduxState = () => {
      const rawAmount = isNaN(parseFloat(this.state.rawAmount.toString()))
        ? 0
        : parseFloat(this.state.rawAmount.toString());
      const { balanceAmount } = this.props;
      this.props.paymentAmountChange(rawAmount, balanceAmount);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (isAmountFieldChanges) {
        const inputRegex = /^\d*\.?\d{0,2}$/;
        const valueWithoutCurrency = value.replace(/^[^\d]+/, '');
        const numericValue = valueWithoutCurrency.replace(/,/g, '');

        const isRegexValid = inputRegex.test(numericValue);
        if (isRegexValid) {
          this.setState({
            rawAmount: numericValue,
          });
        } else return;
      } else {
        this.setState({
          rawAmount: e.target.value,
        });
      }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { timeout } = this.state;
      clearTimeout(timeout);
      if (e.key === 'Enter') {
        e.currentTarget.blur();
        return;
      } else if (e.key === 'Escape') {
        if (isAmountFieldChanges) {
          onResetClicked();
        } else {
          const eventBlur = e.currentTarget.blur.bind(e.target); // this will invoke onBlur func
          this.setState(
            {
              usePrevAmount: true,
            },
            () => {
              eventBlur();
            }
          );
        }
        return;
      }

      this.setState({
        timeout: setTimeout(updateReduxState, isAmountFieldChanges ? 50 : 350),
      });
    };

    const onLabelClick = () => {
      if (isSpOrAp) {
        this.setState({
          isSchedulePayTooltip: true,
        });
        return;
      }
      const {
        payment: { partialPaymentEnabled },
      } = this.props;
      // Partial payment flag that comes from server determines whether we allow the amount to be changed.
      if (!partialPaymentEnabled || isPaymentsBlocked() || paymentMethodType === 'nanopay') {
        return;
      }

      this.setState({
        editMode: true,
        touched: true,
        timeout: setTimeout(updateReduxState, isAmountFieldChanges ? 50 : 350),
      });
      this.scrollToRef();
    };

    const onResetClicked = (e?: React.MouseEvent) => {
      e?.stopPropagation();
      e?.nativeEvent.stopImmediatePropagation();
      this.props.paymentAmountReset();
      this.setState({
        editMode: false,
        touched: false,
        prevAmount: Number(this.props.payment.defaultInputAmount),
        rawAmount: this.props.payment.defaultInputAmount,
      });
    };

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      const select = e.currentTarget.select.bind(e.target);
      this.setState(
        {
          editMode: true,
          touched: true,
          timeout: setTimeout(updateReduxState, isAmountFieldChanges ? 50 : 350),
        },
        () => {
          select();
        }
      );
    };

    const maybeShowOutstandingBalance = () => {
      const {
        currency,
        balanceAmount,
        payment: { inputAmount },
        achOnlineConvenienceFeeAmount = 0,
      } = this.props;

      const outstandingBalance = parseFloat(balanceAmount) - parseFloat(String(inputAmount));
      if (outstandingBalance > 0) {
        return (
          <Fragment>
            <div className="balance-due">
              <span className="balance-due-message">
                <FormattedMessage
                  id="INVOICE_SUMMARY_INVOICE_OUTSTANDING_BALANCE"
                  defaultMessage="Outstanding balance"
                />
                :&nbsp;
              </span>
              <span className="currency-value">
                <FormattedNumber style="currency" currency={currency} value={outstandingBalance} />
              </span>
            </div>
          </Fragment>
        );
      } else if (!achOnlineConvenienceFeeAmount && outstandingBalance < 0) {
        return (
          <Fragment>
            <div className="balance-due">
              <span
                className="balance-due-message"
                onMouseEnter={() =>
                  this.setState({
                    isCreditTooltipOpen: true,
                  })
                }
                onMouseLeave={() =>
                  this.setState({
                    isCreditTooltipOpen: false,
                  })
                }
              >
                <span id="balance-due-credit" style={{ borderBottom: '2px dotted' }}>
                  <FormattedMessage id="payflow.creditsLabel" defaultMessage="Credit" />{' '}
                </span>
                :
                <span className="balance-due-credit-amount" style={{ color: colors.green03 }}>
                  &nbsp;+
                  <FormattedNumber
                    style="currency"
                    currency={currency}
                    value={Math.abs(outstandingBalance)}
                  />
                </span>
              </span>
              <CpPopover
                className="cp-tooltip-wrapper"
                innerClassName="cp-tooltip"
                placement="bottom-start"
                isOpen={this.state.isCreditTooltipOpen}
                target="balance-due-credit"
              >
                <FormattedMessage
                  id="payflow.credit_amount_message"
                  defaultMessage="A credit will be issued to your account because you׳re overpaying your invoice"
                />
              </CpPopover>
            </div>
          </Fragment>
        );
      }
      return null;
    };

    const isPaymentsBlocked = () => {
      const { featureFlags } = this.props;
      return featureFlags && featureFlags['block-payments'];
    };

    const isEditButtonBlocked = () => {
      const { payment = { paymentMethodType: '' } } = this.props;
      return payment && payment.paymentMethodType === 'nanopay';
    };

    const updateIsTooltipOpen = () => {
      if (isSpOrAp) {
        this.setState({
          isSchedulePayTooltip: !this.state.isSchedulePayTooltip,
        });
      }
    };

    const {
      intl,
      currency,
      balanceAmount,
      payment: {
        inputAmount,
        defaultInputAmount,
        paymentStatus,
        isAmountValid,
        paymentMethodType,
        allowOverPay,
        partialPaymentEnabled,
        isDateScheduled,
        isAutoPayOn,
      },
      region,
      featureFlags,
      achOnlineConvenienceFeeAmount,
      achOnlineConvenienceFeeEnabled,
    } = this.props;
    const isSpOrAp = isDateScheduled || isAutoPayOn;

    let displayAmount;

    if (!this.state.editMode) {
      let amount = Number(String(this.props.payment.inputAmount));
      amount = isNaN(amount) ? 0 : amount;
      displayAmount = intl.formatNumber(amount, { style: 'currency', currency });
    } else {
      displayAmount = this.state.rawAmount;
    }

    const editButton = (
      <span
        className="amount-label-icon"
        id="loadable-edit-icon"
        onFocus={onLabelClick}
        onClick={onLabelClick}
        onTouchStart={onLabelClick}
      >
        <CpPopover
          className="cp-tooltip-wrapper"
          innerClassName="cp-tooltip"
          placement="top-end"
          isOpen={this.state.isSchedulePayTooltip}
          target="edit"
        >
          <FormattedMessage
            id="SCHEDULE_PAY_EDIT_AMOUNT_DISABLED"
            description="payment amount edit disabled reason"
            defaultMessage="You have to pay in full if you schedule a payment date other than today. You can’t change the amount."
          />
        </CpPopover>
        <button id="edit" className="input-btn-text edit" disabled={isSpOrAp}>
          <FormattedMessage id="PAYFLOW_EDIT_AMOUNT" defaultMessage="Edit amount" />
        </button>
      </span>
    );

    const resetButton = (
      <span className="amount-label-icon" id="loadable-reset-icon" onClick={onResetClicked}>
        <CpPopover
          className="cp-tooltip-wrapper"
          innerClassName="cp-tooltip"
          placement="top-end"
          isOpen={this.state.isSchedulePayTooltip}
          target="reset"
        >
          <FormattedMessage
            id="SCHEDULE_PAY_EDIT_AMOUNT_DISABLED"
            description="payment amount edit disabled reason"
            defaultMessage="You have to pay in full if you schedule a payment date other than today. You can’t change the amount."
          />
        </CpPopover>
        <button id="reset" className="input-btn-text" onClick={onResetClicked}>
          <FormattedMessage id="PAYFLOW_RESET" defaultMessage="Reset" />
        </button>
      </span>
    );

    const renderButtonInEditMode = ({ buttonName }: { buttonName: 'save' | 'reset' }) => (
      <button
        id={buttonName}
        className="input-btn-text edit-mode"
        onClick={buttonName === 'save' ? onBlur : onResetClicked}
      >
        <FormattedMessage
          id={buttonName === 'save' ? 'PAYFLOW_SAVE' : 'PAYFLOW_RESET'}
          defaultMessage={buttonName === 'save' ? 'Save' : 'Reset'}
        />
      </button>
    );

    const actionButton =
      defaultInputAmount && inputAmount !== defaultInputAmount ? resetButton : editButton;

    // We need this function to enable the usage of decimal number
    const formattedAmount =
      isAmountFieldChanges &&
      formatNumberWithCommaAndCurrency({
        value: this.state.rawAmount,
        currency: getCurrencySymbol(currency),
      });

    const cleanValue = isAmountFieldChanges && formattedAmount.replace(/^[^\d]+/, '');

    return (
      <div className="payment-amount-input-wrapper" ref={this.ref}>
        {this.state.editMode && isAmountFieldChanges && (
          <div className="edit-payment-amount-wrapper">
            <div className="edit-payment-amount-input">
              <AutoGrowingInput
                inputMode="decimal"
                type="text"
                maxLength={16}
                value={formattedAmount}
                cleanValue={cleanValue}
                isError={!isAmountValid}
                onChange={onChange}
                /** Added setTimeout because of the reset button in edit mode - its going to the onBlur automatically instead (resource: https://github.com/facebook/react/issues/4210) **/
                onBlur={() => setTimeout(onBlur, 500)}
                onFocus={onFocus}
                autoFocus
                onKeyDown={handleKeyPress}
                disabled={
                  paymentStatus === TXN_MAP.STATUS.IN_PROGRESS ||
                  !partialPaymentEnabled ||
                  isPaymentsBlocked() ||
                  isEditButtonBlocked()
                }
              />
            </div>
            <>
              <div className="amount-label-icon input-btn">
                {renderButtonInEditMode({ buttonName: 'save' })}
              </div>
              <div className="amount-label-icon input-btn">
                {renderButtonInEditMode({ buttonName: 'reset' })}
              </div>
            </>
          </div>
        )}
        {this.state.editMode && !isAmountFieldChanges && (
          <BaseInput
            type="text"
            inputMode="decimal"
            value={String(this.state.rawAmount)}
            onKeyDown={handleKeyPress}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            pattern="[0-9]*[.]?[0-9]{0,2}"
            autoFocus
            disabled={
              paymentStatus === TXN_MAP.STATUS.IN_PROGRESS ||
              !partialPaymentEnabled ||
              isPaymentsBlocked() ||
              isEditButtonBlocked()
            }
          />
        )}
        {!this.state.editMode && (
          <div
            className="amount-label-icon input-btn"
            onMouseEnter={updateIsTooltipOpen}
            onMouseLeave={updateIsTooltipOpen}
          >
            <label
              className="display-amount"
              onFocus={onLabelClick}
              onClick={onLabelClick}
              onTouchStart={onLabelClick}
              data-testid="sale-display-amount"
            >
              {displayAmount}
            </label>
            {partialPaymentEnabled &&
              !isPaymentsBlocked() &&
              !isEditButtonBlocked() &&
              actionButton}
          </div>
        )}
        {!isAmountValid && (
          <span className="error-message-wrapper">
            <ErrorAlert />
            &nbsp;
            <span className="error-message payment-amount-message">
              <FormattedMessage
                id="PAYFLOW_PAYMENT_RANGE_AMOUNT"
                values={{
                  0: (
                    <FormattedNumber
                      style="currency"
                      currency={currency}
                      value={AMOUNT_CONFIG.MIN_PAYMENT}
                    />
                  ),
                  1: (
                    <FormattedNumber
                      style="currency"
                      currency={currency}
                      value={PaymentAmountValidator.getMaxPaymentAmount(
                        balanceAmount,
                        paymentMethodType,
                        allowOverPay,
                        region,
                        featureFlags
                      )}
                    />
                  ),
                }}
                defaultMessage="Invalid Field"
              />
            </span>
          </span>
        )}
        <div className="payment-data">
          <span>{maybeShowOutstandingBalance()}</span>
        </div>
        {achOnlineConvenienceFeeEnabled && (
          <span className="ach-conveience-fee-note">
            <FormattedMessage
              id="ACH_CONVEIENCE_FEE_NOTE"
              defaultMessage="Includes a ${0} convenience fee"
              values={{
                0: achOnlineConvenienceFeeAmount,
              }}
            />
          </span>
        )}
        {/* language=scss */}
        <style jsx>{`
          :global(.balance-due-message) {
            color: ${colors.gray02};
            font-size: ${fontSize.xs};
            font-family: AvenirNextforINTUIT-Regular;
          }

          :global(.balance-due-credit-amount) {
            font-family: AvenirNextforINTUIT-Demi;
          }

          :global(.currency-value) {
            font-size: ${fontSize.xs};
            color: ${colors.gray02};
            font-family: AvenirNextforINTUIT-Medium;
          }

          .payment-amount-message {
            font-style: normal;
            color: ${colors.darkError};
          }

          .payment-amount-input-wrapper {
            :global(input) {
              padding: 0;
              margin-bottom: 2px;
              font-size: ${fontSize.xl};
              font-family: AvenirNextforINTUIT-Demi;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                appearance: none;
                margin: 0;
              }
            }
          }

          .edit-payment-amount-wrapper {
            display: flex;
            align-items: flex-end;
            gap: 8px;
          }

          .edit-payment-amount-input {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;
            font-size: ${fontSize.xl};
            font-family: AvenirNextforINTUIT-Demi;
            height: 39px;
          }

          .input-btn {
            padding: 0;
            border: none;
            background-color: inherit;

            :global(.input-btn-text) {
              cursor: pointer;
              color: ${colors.gray03};
              font-family: inherit;
              background-color: inherit;
              border: none;
              text-decoration: underline;
              white-space: nowrap;
              -webkit-font-smoothing: antialiased;
            }

            :global(.edit-mode) {
              text-underline-offset: 2px;
              padding: 0;
              margin: 0;
            }
          }

          :global(.amount-label-icon) {
            display: flex;
            align-items: baseline;
          }

          .display-amount {
            cursor: ${partialPaymentEnabled ? 'pointer' : ''};
            display: block;
            position: relative;
            line-height: 39px;
            font-size: ${fontSize.xl};
            font-family: AvenirNextforINTUIT-Demi;
            @media screen and (max-width: ${breakpoints.md}) {
              font-size: ${fontSize.lg};
            }
          }

          .payment-data {
            display: none;

            @media screen and (max-width: ${breakpoints.md}) {
              display: flex;
              justify-content: end;
            }
          }

          .ach-conveience-fee-note {
            font-size: ${fontSize.xs};
            font-family: inherit;
            display: flex;
            margin-top: 4px;
            color: ${colors.gray02};
          }
        `}</style>
      </div>
    );
  }

  scrollToRef = () => {
    if (window.innerWidth <= 768 && this.ref.current) {
      window.scrollTo({ behavior: 'smooth', top: this.ref.current.offsetTop - 25 });
    }
  };
}

export default injectIntl(PaymentAmountInput);
